import { add, isBefore } from 'date-fns'

const signupStateKey = 'neon:signup-state'

/**
 * Persists the current sign up state to local storage
 * @param {string} voucher The voucher code provided by the user
 * @param {string} plan Selected plan
 */
export const saveSignupState = (voucher, plan) => {
  const signupState = {
    voucher,
    plan,
    paymentScreenSeen: Date.now()
  }

  localStorage.setItem(signupStateKey, JSON.stringify(signupState))
}

/**
 * Clears any persisted sign up state
 */
export const clearSignupState = () => {
  localStorage.removeItem(signupStateKey)
}

/**
 * Retrieves the persisted sign up state from local storage
 */
export const getSignupState = () => {
  const storedState = localStorage.getItem(signupStateKey)
  return storedState ? JSON.parse(storedState) : {}
}

/**
 * Determines whether the sign up abandoned banner should be shown
 * based on when the user attempted signing up
 */
export const shouldShowAbandonedSignupBanner = () => {
  const { paymentScreenSeen } = getSignupState()
  if (!paymentScreenSeen) return false

  return isBefore(
    Date.now(),
    add(paymentScreenSeen, {
      weeks: 1
    })
  )
}
